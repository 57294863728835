import i18n from '@/i18n'

export const defaultPowerButton = [
  {
    key: 'newBossPower',
    power: {
      powerBackend: [
        {
          id: 1,
          name: i18n.t('backend'),
          children: [
            {
              id: 43,
              name: i18n.t('basicInformation'),
              children: [
                {
                  id: 2,
                  name: i18n.t('menu'),
                  authName: 'menu',
                },
                {
                  id: 3,
                  name: i18n.t('product'),
                  authName: 'product',
                },
                {
                  id: 28,
                  name: i18n.t('table'),
                  authName: 'table',
                },
                {
                  id: 29,
                  name: i18n.t('staff'),
                  authName: 'staff',
                },
              ],
            },
            {
              id: 44,
              name: i18n.t('billsAndStatistics'),
              children: [
                {
                  id: 31,
                  name: i18n.t('allBills'),
                  authName: 'allBills',
                },
                {
                  id: 32,
                  name: i18n.t('vouchers'),
                  authName: 'vouchers',
                },
                {
                  id: 33,
                  name: i18n.t('marketingStatistics'),
                  authName: 'marketingStatistics',
                },
                {
                  id: 34,
                  name: i18n.t('exportAndPrint'),
                  authName: 'exportAndPrint',
                },
              ],
            },
            {
              id: 45,
              name: i18n.t('otherSetting'),
              children: [
                {
                  id: 36,
                  name: i18n.t('printer'),
                  authName: 'printer',
                },
                {
                  id: 37,
                  name: i18n.t('printTemplate'),
                  authName: 'printTemplate',
                },
                // {
                //   id: 6,
                //   name: i18n.t('setting'),
                //   authName: 'setting',
                // },
              ],
            },
            // {
            //   id: 4,
            //   name: i18n.t('bill'),
            //   authName: 'bill',
            // },
            // {
            //   id: 5,
            //   name: i18n.t('print'),
            //   authName: 'print',
            // },
            // {
            //   id: 35,
            //   name: i18n.t('marketingStatistics'),
            //   authName: 'marketingStatistics',
            // },
            // {
            //   id: 38,
            //   name: i18n.t('distributionDistance'),
            //   authName: 'distributionDistance',
            // },
            // {
            //   id: 39,
            //   name: i18n.t('openTime'),
            //   authName: 'openTime',
            // },
            // {
            //   id: 40,
            //   name: i18n.t('restaurantImage'),
            //   authName: 'restaurantImage',
            // },
            // {
            //   id: 41,
            //   name: i18n.t('selfServiceOrderingMachine'),
            //   authName: 'selfServiceOrderingMachine',
            // },
            // {
            //   id: 42,
            //   name: i18n.t('pay'),
            //   authName: 'pay',
            // },
          ],
        },
      ],
      powerFront: [
        {
          id: 7,
          name: i18n.t('frontDesk'),
          children: [
            {
              id: 8,
              name: i18n.t('openNewTable'),
              children: [
                {
                  id: 9,
                  name: i18n.t('openNewTable'),
                  authName: 'openNewTable',
                },
                {
                  id: 10,
                  name: i18n.t('openTogoTable'),
                  authName: 'openTogoTable',
                },
                {
                  id: 11,
                  name: i18n.t('enterOtherTable'),
                  authName: 'enterOtherTable',
                },
              ],
            },
            {
              id: 12,
              name: i18n.t('checkOut'),
              children: [
                {
                  id: 13,
                  name: i18n.t('checkOut'),
                  authName: 'checkOut',
                },
              ],
            },
            {
              id: 14,
              name: i18n.t('returnDish'),
              children: [
                {
                  id: 15,
                  name: i18n.t('canReturnDish'),
                  authName: 'returnDish',
                },
              ],
            },
            {
              id: 16,
              name: i18n.t('changeTable'),
              children: [
                {
                  id: 17,
                  name: i18n.t('wholeOrderChangeTable'),
                  authName: 'wholeOrderChangeTable',
                },
                {
                  id: 18,
                  name: i18n.t('turnToOtherTable'),
                  authName: 'turnToOtherTable',
                },
                {
                  id: 19,
                  name: i18n.t('dishChangeTable'),
                  authName: 'dishChangeTable',
                },
                {
                  id: 20,
                  name: i18n.t('dishChangeOtherTable'),
                  authName: 'dishChangeOtherTable',
                },
              ],
            },
            {
              id: 21,
              name: i18n.t('discount'),
              children: [
                {
                  id: 22,
                  name: i18n.t('singleDiscount'),
                  authName: 'singleDiscount',
                },
                {
                  id: 23,
                  name: i18n.t('orderDiscountPercentage'),
                  authName: 'orderDiscountPercentage',
                },
                {
                  id: 24,
                  name: i18n.t('customDiscount'),
                  authName: 'customDiscount',
                },
              ],
            },
            {
              id: 25,
              name: i18n.t('mergeTable'),
              children: [
                {
                  id: 26,
                  name: i18n.t('canMergeTable'),
                  authName: 'mergeTable',
                },
                {
                  id: 27,
                  name: i18n.t('mergeOtherPersonTable'),
                  authName: 'mergeOtherPersonTable',
                },
              ],
            },
          ],
        },
      ],
    },
    label: 'Boss', // 老板
  },
  {
    key: 'newManagerPower',
    power: {
      powerFront: [{
        id: 10,
        name: 'openTogoTable', // 开启外卖桌
        authName: 'openTogoTable',
      }, {
        id: 11,
        name: 'enterOtherTable', // 进入其他人的桌子
        authName: 'enterOtherTable',
      }, {
        id: 13,
        name: 'checkOut', // 结账
        authName: 'checkOut',
      }],
      powerBackend: [{
        id: 2,
        name: 'menu', // 菜单
        authName: 'menu',
      }, {
        id: 3,
        name: 'product', // 商品
        authName: 'product',
      }],
    },
    label: 'Manger', // 经理
  },
  {
    key: 'newStaffPower',
    power: {
      powerFront: [{
        id: 10,
        name: 'openTogoTable', // 开启外卖桌
        authName: 'openTogoTable',
      }, {
        id: 11,
        name: 'enterOtherTable', // 进入其他人的桌子
        authName: 'enterOtherTable',
      }, {
        id: 13,
        name: 'checkOut', // 结账
        authName: 'checkOut',
      }],
      powerBackend: [{
        id: 2,
        name: 'menu', // 菜单
        authName: 'menu',
      }, {
        id: 3,
        name: 'product', // 商品
        authName: 'product',
      }],
    },
    label: 'staff', // 员工
  },
  {
    key: 'newHelperPower',
    power: {
      powerFront: [{
        id: 10,
        name: 'openTogoTable', // 开启外卖桌
        authName: 'openTogoTable',
      }, {
        id: 11,
        name: 'enterOtherTable', // 进入其他人的桌子
        authName: 'enterOtherTable',
      }, {
        id: 13,
        name: 'checkOut', // 结账
        authName: 'checkOut',
      }],
      powerBackend: [{
        id: 2,
        name: 'menu', // 菜单
        authName: 'menu',
      }, {
        id: 3,
        name: 'product', // 商品
        authName: 'product',
      }],
    },
    label: 'junior_waiter',
  },
]

export const power = {
  powerBackend: [
    {
      id: 1,
      name: i18n.t('backend'),
      children: [
        {
          id: 43,
          name: i18n.t('basicInformation'),
          children: [
            {
              id: 2,
              name: i18n.t('menu'),
              authName: 'menu',
            },
            {
              id: 3,
              name: i18n.t('product'),
              authName: 'product',
            },
            {
              id: 28,
              name: i18n.t('table'),
              authName: 'table',
            },
            {
              id: 29,
              name: i18n.t('staff'),
              authName: 'staff',
            },
          ],
        },
        {
          id: 44,
          name: i18n.t('billsAndStatistics'),
          children: [
            {
              id: 31,
              name: i18n.t('allBills'),
              authName: 'allBills',
            },
            {
              id: 32,
              name: i18n.t('vouchers'),
              authName: 'vouchers',
            },
            {
              id: 33,
              name: i18n.t('marketingStatistics'),
              authName: 'marketingStatistics',
            },
            {
              id: 34,
              name: i18n.t('exportAndPrint'),
              authName: 'exportAndPrint',
            },
          ],
        },
        {
          id: 45,
          name: i18n.t('otherSetting'),
          children: [
            {
              id: 36,
              name: i18n.t('printer'),
              authName: 'printer',
            },
            {
              id: 37,
              name: i18n.t('printTemplate'),
              authName: 'printTemplate',
            },
            // {
            //   id: 6,
            //   name: i18n.t('setting'),
            //   authName: 'setting',
            // },
          ],
        },
        // {
        //   id: 4,
        //   name: i18n.t('bill'),
        //   authName: 'bill',
        // },
        // {
        //   id: 5,
        //   name: i18n.t('print'),
        //   authName: 'print',
        // },
        // {
        //   id: 35,
        //   name: i18n.t('marketingStatistics'),
        //   authName: 'marketingStatistics',
        // },
        // {
        //   id: 38,
        //   name: i18n.t('distributionDistance'),
        //   authName: 'distributionDistance',
        // },
        // {
        //   id: 39,
        //   name: i18n.t('openTime'),
        //   authName: 'openTime',
        // },
        // {
        //   id: 40,
        //   name: i18n.t('restaurantImage'),
        //   authName: 'restaurantImage',
        // },
        // {
        //   id: 41,
        //   name: i18n.t('selfServiceOrderingMachine'),
        //   authName: 'selfServiceOrderingMachine',
        // },
        // {
        //   id: 42,
        //   name: i18n.t('pay'),
        //   authName: 'pay',
        // },
      ],
    },
  ],
  powerFront: [
    {
      id: 7,
      name: i18n.t('frontDesk'),
      children: [
        {
          id: 8,
          name: i18n.t('openNewTable'),
          children: [
            {
              id: 9,
              name: i18n.t('openNewTable'),
              authName: 'openNewTable',
            },
            {
              id: 10,
              name: i18n.t('openTogoTable'),
              authName: 'openTogoTable',
            },
            {
              id: 11,
              name: i18n.t('enterOtherTable'),
              authName: 'enterOtherTable',
            },
          ],
        },
        {
          id: 12,
          name: i18n.t('checkOut'),
          children: [
            {
              id: 13,
              name: i18n.t('checkOut'),
              authName: 'checkOut',
            },
          ],
        },
        {
          id: 14,
          name: i18n.t('returnDish'),
          children: [
            {
              id: 15,
              name: i18n.t('canReturnDish'),
              authName: 'returnDish',
            },
          ],
        },
        {
          id: 16,
          name: i18n.t('changeTable'),
          children: [
            {
              id: 17,
              name: i18n.t('wholeOrderChangeTable'),
              authName: 'wholeOrderChangeTable',
            },
            {
              id: 18,
              name: i18n.t('turnToOtherTable'),
              authName: 'turnToOtherTable',
            },
            {
              id: 19,
              name: i18n.t('dishChangeTable'),
              authName: 'dishChangeTable',
            },
            {
              id: 20,
              name: i18n.t('dishChangeOtherTable'),
              authName: 'dishChangeOtherTable',
            },
          ],
        },
        {
          id: 21,
          name: i18n.t('discount'),
          children: [
            {
              id: 22,
              name: i18n.t('singleDiscount'),
              authName: 'singleDiscount',
            },
            {
              id: 23,
              name: i18n.t('orderDiscountPercentage'),
              authName: 'orderDiscountPercentage',
            },
            {
              id: 24,
              name: i18n.t('customDiscount'),
              authName: 'customDiscount',
            },
          ],
        },
        {
          id: 25,
          name: i18n.t('mergeTable'),
          children: [
            {
              id: 26,
              name: i18n.t('canMergeTable'),
              authName: 'mergeTable',
            },
            {
              id: 27,
              name: i18n.t('mergeOtherPersonTable'),
              authName: 'mergeOtherPersonTable',
            },
          ],
        },
      ],
    },
  ],
}
